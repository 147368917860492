import { render, staticRenderFns } from "./payment-success.html?vue&type=template&id=8ef0f1b0&scoped=true&"
import script from "./payment-success.js?vue&type=script&lang=js&"
export * from "./payment-success.js?vue&type=script&lang=js&"
import style0 from "./payment-success.scss?vue&type=style&index=0&id=8ef0f1b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ef0f1b0",
  null
  
)

export default component.exports